<!-- Settings -->
<template>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center my-10 px-8 px-lg-10">
                    <div class="col-xl-12 col-xxl-10 invoicepage">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form printSection">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                 <!-- <el-row>
                                    <el-col :span="24" align="right" >
                                      <el-dropdown  @command="handleActions($event)" size="medium" split-button class="action_dropdown">
                                        Actions
                                        <el-dropdown-menu slot="dropdown" class="action_dropdown">
                                          <el-dropdown-item :command="4" v-if="view.order_status == 'waiting'">Approve</el-dropdown-item>
                                          <el-dropdown-item :command="1" v-if="view.order_status != 'waiting'">Print</el-dropdown-item>
                                          <el-dropdown-item :command="2" v-if="view.order_status != 'waiting'">Download</el-dropdown-item>
                                          <el-dropdown-item :command="3" v-if="view.order_status != 'waiting'">Send Email</el-dropdown-item>
                                        </el-dropdown-menu>
                                      </el-dropdown>
                                    </el-col>
                                  </el-row> -->
                                <el-row>
                                    <el-col :span="12" align="left">
                                        <span v-loadimage="store_address.website_logo?store_address.website_logo:''"  ></span>
                                    </el-col>
                                    <el-col :span="12" align="right">
                                            <b >
                                    <label class="mt-10">
                                      {{store_address.address?store_address.address:''}}, 
                                      {{store_address.city_details? store_address.city_details.city_name: ''}}, 
                                      {{store_address.state_details? store_address.state_details.state_name:''}} -{{store_address.post_code}}</label><br>
                                    <label>Phone: {{store_address.phone?store_address.phone:''}}</label><br>
                                    <label>Fax: {{store_address.fax?store_address.fax:''}}</label><br>
                                    <label>Email: {{store_address.email_address?store_address.email_address:''}}</label>
                                    </b>
                                    <br>
                                    </el-col>
                                  </el-row>
                                   <hr class="invoice-line">
                                  <el-row>                                     
                                      <el-col :span="24" align="center">
                                      <h2 style="font-family: Arial;text-align: center;"><b>RECEIVE PURCHASE ORDER</b></h2>
                                      </el-col>                                                                        
                                  </el-row>
                                   <el-row style="margin-top:5px; margin-bottom:5px;">
                                       <el-col :span="12"  align="left">
                                           <span><b>PURCHASE ORDER #</b></span><span><b> {{view.purchase_order.po_number}}</b></span>
                                       </el-col>
                                        <el-col :span="12"  align="right">
                                           <span><b>SUPPLIER ID : </b></span><span><b>{{view.supplier.supplier_Id}}</b></span>
                                       </el-col>
                                  </el-row>
                                   <el-row class="add-info">
                                      <el-col :span="8" >
                                          <div class="billing-adr" style="padding-left:10px;">BILL TO :</div>
                                          <div style="padding: 3px;" v-if="view.supplier && view.supplier.company_name" >{{view.supplier.company_name}}</div>
                                          <div style="padding: 3px;" v-if="view.supplier && (view.supplier.unit_no || view.supplier.street)">{{view.supplier.street}}{{view.supplier.unit_no ? ', Unit No : '+view.supplier.unit_no :''  }} </div>
                                          <div style="padding: 3px;" v-if="view.supplier && view.supplier.city">{{view.supplier.city? view.supplier.city.city_name : ''}}</div>
                                          <div style="padding: 3px;" v-if="view.supplier && view.supplier.state">{{view.supplier.state? view.supplier.state.state_name + (view.supplier.postal_code ? ' - '+view.supplier.postal_code : ''):''}}</div>
                                      </el-col>
                                       <el-col :span="8" align="">
                                            .
                                      </el-col>
                                       <el-col :span="8" align="left">    
                                         <div class="billing-adr" style="padding-left:10px;" v-if="view.delivery_type == 'Delivery'">SHIP TO :</div>
                                         <div class="billing-adr" style="padding-left:10px;" v-else>PickUp :</div>
                                         <div style="padding: 3px;" v-if="view.store_details && view.store_details.website_name && view.delivery_type == 'Delivery'">{{view.store_details?view.store_details.website_name:''}}</div>
                                         <div style="padding: 3px;" v-if="view.supplier && view.supplier.company_name && view.delivery_type == 'PickUp'">{{view.supplier.company_name}}</div>
                                         <div style="padding: 3px;" v-if="view.store_details.address || view.store_details.unit_no">{{view.store_details.address}}{{view.store_details.unit_no ? ', Unit No : '+view.store_details.unit_no :''  }}</div>
                                          <div style="padding: 3px;" v-if="view.store_details.city_details && view.store_details.city_details.city_name ">{{view.store_details.city_details? view.store_details.city_details.city_name : '' }}</div>
                                          <div style="padding: 3px;" v-if="view.store_details.state_details && view.store_details.state_details.state_name ">{{view.store_details.state_details.state_name +(view.store_details.post_code ? ' - '+view.store_details.post_code : '')}}</div>
                                      </el-col>
                                    </el-row>
                                     <div class="row">
                                      <div class="col-xl-12 ">                                       
                                          <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover" style="margin-top:20px;">
                                            <tbody role="rowgroup">
                                                <tr class="billing-cl2 text-center invoice-header" >
                                                    <th aria-colindex="1" role="cell"><div >ITEM #</div></th>
                                                    <th aria-colindex="2" role="cell"><div >DESCRIPTION</div></th>                                                    
                                                    <th aria-colindex="2" role="cell"><div >Qty</div></th>
                                                </tr>
                                                   <tr class="billing-cl3" v-for="item in view.receive_items" :key="item.id">
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{item.supplier_sku?item.supplier_sku:''}}</div></td>
                                                    <td aria-colindex="1" role="cell" ><div class="padding-10">{{item.product?item.product.name:''}} -<b>{{item.product.sku}}</b></div></td>                                                   
                                                    <td aria-colindex="2" role="cell" class="text-center"><div class="padding-10">{{item.receive_quantity}}</div></td>    
                                                </tr>
                                            </tbody>
                                        </table>
                                      </div>
                                     </div>
                                       <el-row class="add-info">
                                          <el-col >
                                            <span><b>RECEIVED DATE : </b></span><span>{{format_date(view.created_at)}}</span>
                                          </el-col>
                                         
                                       </el-row>
                                       <el-row class="add-info">
                                         <el-col >
                                            <span><b>RECEIVED BY : </b></span><span>{{view.receive_verified_by}}</span>
                                          </el-col>
                                       </el-row> 
                                       <el-row class="add-info">
                                         <el-col >
                                            <span><b>COMMENTS : </b></span><span>{{view.comments}}</span>
                                          </el-col>
                                       </el-row>    
                                       <el-row class="add-info">
                                         <el-col >
                                            <div><b>DOCUMENTS : </b></div>
                                            <el-row v-if="view.receive_documents">
                                            <el-col :span="4" 
                                v-for="(
                                  receive_image, k
                                ) in view.receive_documents"
                                :key="k"
                              >
                                <div class="receive_images">
                                
                                  <el-image 
                                    style="width: 100px; height: 100px"
                                    :src="image_url + receive_image.file_name" 
                                    :preview-src-list="imagePreview(image_url + receive_image.file_name)">
                                  </el-image>
                                  <div
                                    class="d-flex justify-content-center pt-3"
                                  >
                                    <el-button
                                      @click="
                                        handleImageDownload(
                                          receive_image.image_decode_url,
                                          receive_image.file_name
                                        )
                                      "
                                      circle
                                      size="small"
                                      type="primary"
                                    >
                                      <i class="fa fa-download text-white"></i>
                                    </el-button>
                                  </div>
                                </div>
                                            </el-col>
                               </el-row>
                                          </el-col>
                                       </el-row>                                      
                            </div>
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {getReceivePurchaseOrderView, downloadPdf,} from "@/api/purchaseorder";
import SendEmail from '@/components/SendEmail';
import ApprovePO from '@/components/PurchaseOrder/approve';
import {globalEvents} from '@/utils/globalEvents';
export default {
  name: 'view-order',
  components: {},
   data() {
    return {
      loading: false,
      view:[],
      viewitem:[], 
      store_address : [],     
      view_type: 'purchaseorder',
      activeTab:'back_orders',
      cancel_list:[],
      back_order:[],
      image_url :process.env.VUE_APP_BASE_IMAGE_URL
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Receive PO List", route: "/recive/purchase/order" }, 
      { title: "Receive Purchase Order" }
    ]);
     globalEvents.$on("approve", (added) => {
        this.fetchData(1);
          if (added) {
            this.fetchData(1);        
          }
    });
  },
  created() {
    this.fetchData(1)   
  },
  
   methods: {
   fetchData() {
      this.loading = true
      getReceivePurchaseOrderView(this.$route.params.po_receive_id).then(response => { 
        this.view = response.data.data 
        this.store_address = response.data.data.store_details     
        this.loading = false
      });
      
    },
     printWindow: function () {		
	     window.print();
    },
    handleImageDownload(data,fileName) {
      if (data) {
        const downloadLink = document.createElement("a");
        downloadLink.href = data;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    },
    imagePreview(image){
      var images = [];
      images.push(image);
      return images

    },
    format_date(value){
        if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
        }
    }, 
     handleActions(evt){
      if (evt === 1){
        this.printWindow();
      }else if (evt === 2){
        this.loading=true;
        downloadPdf(this.$route.params.poId, this.view_type).then(response => {
          //console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'purchaseorder.pdf');
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        }).catch(err => {
          console.log(err);
          this.loading = false;
        });
      }else if (evt === 3){
        let customer = this.view.supplier;
        let order_id = this.view.id;
        let type = this.view_type;

        this.$modal.show(SendEmail, {customer, type, order_id}, 
          { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
      }else if(evt === 4){
            this.$modal.show(ApprovePO, {po_id : this.$route.params.poId}, 
              { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
          }
    },
    
  
   }
   
};
</script>

<style >
    hr.invoice-line{
        border-top: 1px solid black;
        width: 100%;
    }
    .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
    .invoice-header{
        background-color:#366092; 
        color: #ffffff;
    }
    #website-table th, #website-table td {
      border: 1px solid #366092; padding: 5px;
    }
    .tab-row-hover {
        border: 1px solid #366092;
    }
    .add-info{
      margin-bottom: 20px;
    }
    .default-logo img{
      width: 300px;
      margin-top: 30px;
      margin-left: 10px;
    }
    .receive_images{
    display: block;
    padding: 10px;
    border: 1px solid #CACACA;
    background-color: #FFF;
    margin-bottom: 30px;
    }
    @media print {
       
        .print-section, .download-btn, .subheader, .action_dropdown {
            visibility: hidden;
            -webkit-print-color-adjust: exact;
        }
         .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         -webkit-print-color-adjust: exact;
        
    }

    .table th { 
        background-color: #366092 !important; 
         color: #ffffff !important;
         -webkit-print-color-adjust: exact;
    } 
    

}
 
</style>